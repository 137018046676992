import "./Editor.css";

import icon from "assets/icon.svg";
import { signInAnonymously } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { auth, handleTimesAction } from "../utilities/firebaseUtils";
import InformButton from "./InformButton";
import InputArea from "./InputArea";
import LoginPopup from "./LoginPopup";
import OutputArea from "./OutputArea";
import PremiumPopup from "./PremiumPopup";
import PromotionBanner from "./PromotionBanner";
import SnackBar from "./SnackBar";

const Editor = () => {
  const isVisibleRef = useRef(null);
  const emailTimesRef = useRef(null);
  const headerRef = useRef(null);
  const anonymousTimesRef = useRef(null);
  const anonymousRef = useRef("");
  const emailRef = useRef("Anonymous");
  const isPremiumRef = useRef("placeholder");

  const [textarea, setTextarea] = useState("");
  const [message, setMessage] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const [login, setLogin] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

  const [isPremiumPopupOpen, setIsPremiumPopupOpen] = useState(false);
  const [revisionButtonClick, setRevisionButtonClick] = useState(false);
  const [isLoadingForLoginButton, setIsLoadingForLoginButton] = useState(true);
  const [rephraseItem, setRephraseItem] = useState([]);
  const [isPremium, setIsPremium] = useState(false);

  console.log("Initial isPremium state:", isPremium);

  useEffect(() => {
    //live message
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-na1.hs-scripts.com/39576677.js";
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    //login judge
    auth.onAuthStateChanged(async (user) => {
      console.log("Auth state changed. User:", user ? user.email : "No user");
      if (user && !user.isAnonymous) {
        await handleAuthenticatedUser(user);
      } else {
        await handleAnonymousUser();
      }
      setIsLoadingForLoginButton(false);
    });

    //token refresh
    auth.onIdTokenChanged(async (user) => {
      try {
        const userCredential = user ? null : await signInAnonymously(auth);
        const idToken = user ? await user.getIdToken() : userCredential.user.getIdToken();
        headerRef.current = { Authorization: "Bearer " + idToken };
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  const handleAuthenticatedUser = async (user) => {
    console.log("Handling authenticated user:", user.email);
    anonymousTimesRef.current = "NotLogout";
    setIsLoginPopupOpen(false);
    setLogin(true);
    emailRef.current = user.email;

    window.hj('identify', user.uid, {
      email: user.email
    });

    try {
      const decodedToken = await user.getIdTokenResult(true);
      const isPremiumUser = decodedToken.claims.stripeRole === "premium";
      console.log("User premium status:", isPremiumUser);
      setIsPremium(isPremiumUser);
      console.log("Setting isPremium state to:", isPremiumUser);
      isPremiumRef.current = isPremiumUser ? "premium" : "not_premium";
      console.log("isPremiumRef.current set to:", isPremiumRef.current);

      emailTimesRef.current = await handleTimesAction("email", "createUserOrNot", user.email);
      console.log("emailTimesRef.current set to:", emailTimesRef.current);

      setMessage(`Signed in as ${user.email}`);
      setIsVisible(true);
      clearTimeout(isVisibleRef.current);
      isVisibleRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    } catch (error) {
      console.error("Error getting user token:", error);
    }
  };

  const handleAnonymousUser = async () => {
    console.log("Handling anonymous user");
    setLogin(false);
    emailRef.current = "Anonymous";
    emailTimesRef.current = "NotLogin";
    isPremiumRef.current = "NotLogin";
    setIsPremium(false);
    console.log("Setting isPremium state to false for anonymous user");
    const storedAnonymousId = localStorage.getItem("anonymousId");
    if (storedAnonymousId) {
      anonymousRef.current = storedAnonymousId;

      anonymousTimesRef.current = await handleTimesAction("anonymous", "createUserOrNot", storedAnonymousId);
    } else {
      const userCredential = await signInAnonymously(auth);
      const newAnonymousId = userCredential.user.uid;
      localStorage.setItem("anonymousId", newAnonymousId);
      anonymousRef.current = newAnonymousId;
      anonymousTimesRef.current = await handleTimesAction("anonymous", "createUserOrNot", newAnonymousId);
    }
  };

  useEffect(() => {
    console.log("isPremium state changed to:", isPremium);
  }, [isPremium]);

  return (
    <>
      {emailTimesRef.current >= 90 && !isPremium && <PromotionBanner times={emailTimesRef.current} />}

      <div className="navigator-bar-div">
        <div className="phrasely-icon-div">
          <a href="https://phrasely.app">
            <img className="phrasely-icon" src={icon} alt="Phrasely Icon" />
          </a>
        </div>
        <InformButton login={login} setIsPremiumPopupOpen={setIsPremiumPopupOpen} setLogin={setLogin} isLoadingForLoginButton={isLoadingForLoginButton} />
      </div>
      <div className="centered-content">
        <InputArea
          headerRef={headerRef}
          emailRef={emailRef}
          anonymousTimesRef={anonymousTimesRef}
          anonymousRef={anonymousRef}
          emailTimesRef={emailTimesRef}
          setIsPremiumPopupOpen={setIsPremiumPopupOpen}
          isPremiumRef={isPremiumRef}
          textarea={textarea}
          setTextarea={setTextarea}
          placeholderText={placeholderText}
          setPlaceholderText={setPlaceholderText}
          setIsVisible={setIsVisible}
          isVisibleRef={isVisibleRef}
          setMessage={setMessage}
          setIsFlipped={setIsFlipped}
          setIsLoginPopupOpen={setIsLoginPopupOpen}
          setRephraseItem={setRephraseItem}
          setRevisionButtonClick={setRevisionButtonClick}
        />
        <OutputArea
          headerRef={headerRef}
          isFlipped={isFlipped}
          anonymousRef={anonymousRef}
          emailRef={emailRef}
          textarea={textarea}
          setIsFlipped={setIsFlipped}
          rephraseItem={rephraseItem}
          setMessage={setMessage}
          setIsVisible={setIsVisible}
          isVisibleRef={isVisibleRef}
          revisionButtonClick={revisionButtonClick}
          setRevisionButtonClick={setRevisionButtonClick}
        />
      </div>
      <SnackBar message={message} isVisibleRef={isVisibleRef} setIsVisible={setIsVisible} isVisible={isVisible} />
      <LoginPopup isLoginPopupOpen={isLoginPopupOpen} setLogin={setLogin} />
      <PremiumPopup isPremiumPopupOpen={isPremiumPopupOpen} />
      {/* <Footer /> */}
    </>
  );
};

export default Editor;
