import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';

function CustomClaims() {
  const [email, setEmail] = useState('');
  const [customClaims, setCustomClaims] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [stealthifiedText, setStealthifiedText] = useState(null);

  const fetchCustomClaims = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setCustomClaims(null);
    setError(null);
    try {
      const functions = getFunctions();
      const getCustomClaimsByEmail = httpsCallable(functions, 'getCustomClaimsByEmail');
      console.log('Fetching custom claims for email:', email);
      const result = await getCustomClaimsByEmail({email: email});
      setCustomClaims(result.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const stealthifyText = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setStealthifiedText(null);
    try {
      const functions = getFunctions();
      const stealthify = httpsCallable(functions, 'stealthify');
      console.log('Stealthifying text:', prompt);
      const result = await stealthify({ prompt: prompt, rephrase: true });

      // Remove escaped quotes from the result e.g., 
      //"this is the \"stealthified\" text" -> "this is the stealthified text"
      const processedData = result.data.replace(/\\"/g, '');
      setStealthifiedText(processedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Custom Claims - Admin Only</title>
      </Helmet>

      <h1>User Custom Claims</h1>
      <form onSubmit={fetchCustomClaims}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter user email"
          required
        />
        <button type="submit" disabled={loading}>
          Fetch Custom Claims
        </button>
      </form>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {customClaims && (
        <div>
          <h2>Custom Claims for {email}:</h2>
          <pre>{JSON.stringify(customClaims, null, 2)}</pre>
        </div>
      )}

      <h1>Stealthify Text</h1>
      <form onSubmit={stealthifyText}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter text to stealthify"
          required
        />
        <button type="submit" disabled={loading}>
          Stealthify
        </button>
      </form>
      {stealthifiedText && (
        <div>
          <h2>Stealthified Text:</h2>
          <pre>{JSON.stringify(stealthifiedText, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default CustomClaims;