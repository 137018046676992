import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Editor from './editor/Editor';
import CustomClaims from './customclaims/CustomClaims';

function App() {
  console.log('App component rendered');
  
  return (
    <Router>
        <Routes>
          <Route path="*" element={<Editor />} />
          <Route path="/customclaims" element={<CustomClaims />} />
        </Routes>
    </Router>
  );
}

export default App;