import styles from "./PromotionBanner.module.css";

const PromotionBanner = ({ times }) => {
  return (
    <div className={styles.banner}>
      {Math.max(0, 100 - times)} free revisions left! Get 80% off the first month of premium membership with
      coupon code: PHRASELY099
    </div>
  );
};

export default PromotionBanner;
