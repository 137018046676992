import casual from "assets/casual.svg";
import formal from "assets/formal.svg";
import long from "assets/long.svg";
import rephraseIcon from "assets/rephrase.png";
import shorten from "assets/shorten.svg";
import humanize from "assets/humanize.svg";
const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const CATEGORY_ITEMS = [
  { img: rephraseIcon, name: "Rephrase" },
  { img: long, name: "Expand" },
  { img: shorten, name: "Shorten" },
  { img: casual, name: "Casual" },
  { img: formal, name: "Formal" },
  { img: humanize, name: "Humanize" },
];

const OPENAI_TEMPERATURE = 1;
const OPENAI_MAX_TOKEN = 2000;
const ZUPLO_API_URL = "/v1/chat/completions";
const OPENAI_MODEL = "gpt-3.5-turbo-0125";

export { CATEGORY_ITEMS, IS_MOBILE, OPENAI_MAX_TOKEN, OPENAI_MODEL, OPENAI_TEMPERATURE, ZUPLO_API_URL };
