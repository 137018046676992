export const promptFormatter = (rephraseInput, rephraseStyle, rephraseSuggestion, lang) => {
  let system = "";
  let user = "";
  let langJudge = `Responses must be provided in the following language abbreviation: ${lang} `;
  const words = rephraseInput.split(" ");

  if (words.length <= 2 && rephraseSuggestion === "") {
    return {
      system: "",
      user: `Rephrase these words: ${rephraseInput}.`,
    };
  }

  switch (rephraseStyle) {
    case "Rephrase":
      system = `Rephrase text to be more clear and concise: ${rephraseInput}.`;
      break;

    case "revisionExplanation":
      system = `List specific examples to examine how the revision is better than original text based on syntax, word choice, and grammar. Answer must not exceed 100 words. Responses must be provided in the following language abbreviation: ${lang}`;
      user = `Orginal: ${rephraseInput}.\n Revision: ${rephraseSuggestion}.`;
      break;

    case "Shorten":
      let shortenTargetLength = Math.floor(words.length * 0.5);
      system = `Return in ${shortenTargetLength} words. Rephrase text to be more clear, concise, and short: ${rephraseInput}.`;
      break;

    case "Expand":
      let expandTargetLength = words.length + 20;
      system = `Return in ${expandTargetLength} words. Rephrase text to be more clear and expand: ${rephraseInput}.`;
      break;

    case "Make Casual":
      system = `Rephrase text to be more clear, concise, and casual: ${rephraseInput}.`;
      break;

    case "Make Formal":
      system = `Return in ${words.length} words. Rephrase text to be more clear, concise, and formal: ${rephraseInput}.`;
      break;

    default:
      break;
  }
  return { system, user, langJudge };
};
